import React from 'react';
import '../../../css/project.scss';
import { graphql, Link } from 'gatsby';
import Layout from '../../../components/Layout';
import Seo from '../../../components/Seo';
import HeadingDecorated from '../../../components/HeadingDecorated';
import Markdown from '../../../components/Markdown';
import downloadImg from '../../../images/download-large.png';
import arrowLeftImg from '../../../images/arrow-left.png';
import arrowRightImg from '../../../images/arrow-right.png';
import { getLocalizedPages } from '../../nasze-projekty/{strapiProject.slug}';

export const query = graphql`
  query ($slug: String!) {
    project: strapiProject(slug: { eq: $slug }, locale: { eq: "en" }) {
      id
      avatar {
        localFile {
          url
        }
      }
      name
      description {
        data {
          description
        }
      }
      slug
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
    }
    files: allStrapiProjectFile(
      sort: { fields: position, order: ASC }
      filter: { project: { slug: { eq: $slug } }, locale: { eq: "en" } }
    ) {
      edges {
        node {
          name
          locale
          description
          file {
            localFile {
              url
            }
          }
          thumbnail {
            localFile {
              url
            }
          }
        }
      }
    }
    edges: allStrapiProject(
      sort: { fields: position, order: ASC }
      filter: { locale: { eq: "en" } }
    ) {
      edges {
        previous {
          slug
          name
        }
        node {
          slug
        }
        next {
          slug
          name
        }
      }
    }
  }
`;

const ProjectPage = ({ data: { project, files, edges } }) => {
  if (!project) return null;

  const currentEdge = edges.edges.find(
    (edge) => edge.node.slug === project.slug
  );

  return (
    <Layout locale="en" localizedPages={getLocalizedPages(project)}>
      <Seo title={project.name} />
      <div className="project">
        <nav className="project__breadcrumb">
          <Link to="/en/our-projects/">Our projects</Link>
        </nav>

        <HeadingDecorated className="project__heading">
          {project.name}
        </HeadingDecorated>

        <section className="project__about">
          <img
            src={project.avatar.localFile.url}
            className="accent-shadow-responsive project__avatar"
            alt={project.name}
          />
          <h3 className="project__name">About the project</h3>
          <Markdown className="project__description">
            {project.description.data.description}
          </Markdown>
        </section>
        {files.edges.length > 0 && (
          <section className="project__downloads">
            <h3>Download documents</h3>
            <div className="project__files">
              {files.edges.map((file) => (
                <figure className="project__file">
                  <img
                    src={file.node.thumbnail.localFile.url}
                    alt="thumbnail"
                    className="project__file-thumbnail"
                  />
                  <figcaption>
                    <h4>{file.node.name}</h4>
                    <span>{file.node.description}</span>
                    <a href={file.node.file.localFile.url} target="_blank">
                      <img src={downloadImg} alt="download" />
                      Download
                    </a>
                  </figcaption>
                </figure>
              ))}
            </div>
          </section>
        )}
        {currentEdge && (
          <nav className="project__footer-nav">
            {currentEdge.previous === null ? (
              <Link to="/en/our-projects/">
                <img src={arrowLeftImg} alt="&larr;" />
                Our projects
              </Link>
            ) : (
              <Link to={`/en/our-projects/${currentEdge.previous.slug}/`}>
                <img src={arrowLeftImg} alt="&larr;" />
                {currentEdge.previous.name}
              </Link>
            )}

            {currentEdge.next && (
              <div className="project__footer-nav-divider"></div>
            )}

            {currentEdge.next !== null && (
              <Link to={`/en/our-projects/${currentEdge.next.slug}/`}>
                {currentEdge.next.name}
                <img src={arrowRightImg} alt="&rarr;" />
              </Link>
            )}
          </nav>
        )}
      </div>
    </Layout>
  );
};

export default ProjectPage;
