import React from 'react';
import '../../css/project.scss';
import { graphql, Link } from 'gatsby';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import HeadingDecorated from '../../components/HeadingDecorated';
import Markdown from '../../components/Markdown';
import downloadImg from '../../images/download-large.png';
import arrowLeftImg from '../../images/arrow-left.png';
import arrowRightImg from '../../images/arrow-right.png';

const LOCALE = 'pl';

export const query = graphql`
  query ($slug: String!) {
    project: strapiProject(slug: { eq: $slug }) {
      id
      avatar {
        localFile {
          url
        }
      }
      name
      slug
      description {
        data {
          description
        }
      }
      localizations {
        data {
          attributes {
            slug
            locale
          }
        }
      }
      files: project_files {
        name
        locale
        description
        file {
          localFile {
            url
          }
        }
        thumbnail {
          localFile {
            url
          }
        }
      }
    }
    edges: allStrapiProject(
      sort: { fields: position, order: ASC }
      filter: { locale: { eq: "pl-PL" } }
    ) {
      edges {
        previous {
          slug
          name
        }
        node {
          id
        }
        next {
          slug
          name
        }
      }
    }
  }
`;

export const getLocalizedPages = (project) => {
  if (!project.localizations.data.length) return {};

  const slugs = project.localizations.data.reduce(
    (result, localization) => {
      return {
        ...result,
        [localization.attributes.locale]: localization.attributes.slug,
      };
    },
    { [project.locale]: project.slug }
  );

  return {
    pl: `/nasze-projekty/${slugs['pl-PL']}/`,
    en: `/our-projects/${slugs['en']}/`,
  };
};

const ProjectPage = ({
  data: {
    project,
    edges: { edges },
  },
}) => {
  if (!project) return null;

  const currentEdge = edges.find((edge) => edge.node.id === project.id);

  const filteredFiles = project.files.filter(({ locale }) => locale === LOCALE);

  return (
    <Layout localizedPages={getLocalizedPages(project)}>
      <Seo title={project.name} />
      <div className="project">
        <nav className="project__breadcrumb">
          <Link to="/nasze-projekty/">Nasze projekty</Link>
        </nav>

        <HeadingDecorated className="project__heading">
          {project.name}
        </HeadingDecorated>

        <section className="project__about">
          <img
            src={project.avatar.localFile.url}
            className="accent-shadow-responsive project__avatar"
            alt={project.name || project.localizations.data[0].attributes.name}
          />
          <h3 className="project__name">O projekcie</h3>
          <Markdown className="project__description">
            {project?.description?.data?.description ||
              project.localizations.data[0].attributes.description}
          </Markdown>
        </section>
        {filteredFiles.length > 0 && (
          <section className="project__downloads">
            <h3>Dokumenty do pobrania</h3>
            <div className="project__files">
              {filteredFiles.map((file) => (
                <figure className="project__file">
                  <img
                    src={file.thumbnail.localFile.url}
                    alt="thumbnail"
                    className="project__file-thumbnail"
                  />
                  <figcaption>
                    <h4>{file.name}</h4>
                    <span>{file.description}</span>
                    <a href={file.file.localFile.url} target="_blank">
                      <img src={downloadImg} alt="download" />
                      Pobierz
                    </a>
                  </figcaption>
                </figure>
              ))}
            </div>
          </section>
        )}
        {currentEdge && (
          <nav className="project__footer-nav">
            {currentEdge.previous === null ? (
              <Link to="/nasze-projekty/">
                <img src={arrowLeftImg} alt="&larr;" />
                Nasze projekty
              </Link>
            ) : (
              <Link to={`/nasze-projekty/${currentEdge.previous.slug}/`}>
                <img src={arrowLeftImg} alt="&larr;" />
                {currentEdge.previous.name}
              </Link>
            )}

            {currentEdge.next && (
              <div className="project__footer-nav-divider"></div>
            )}

            {currentEdge.next !== null && (
              <Link to={`/nasze-projekty/${currentEdge.next.slug}/`}>
                {currentEdge.next.name}
                <img src={arrowRightImg} alt="&rarr;" />
              </Link>
            )}
          </nav>
        )}
      </div>
    </Layout>
  );
};

export default ProjectPage;
